import { createSlice } from '@reduxjs/toolkit';
import { Slot, SlotData, SwapSlot } from '../../types';
import { getSlots, getSlotsByDate, getSwapSlots } from './thunks';

interface InitialState {
  loading: boolean;
  error: any;
  data: { [serviceIds: string]: SlotData };
  dateSlots: {
    loading: boolean;
    data: Slot[];
    error: any;
    lastTimestamp: number;
  };
  slots: {
    loading: boolean;
    data: {};
    error: any;
    lastTimestamp: number;
  };
  swapSlots: {
    loading: boolean;
    data: SwapSlot[] | null;
    error: any;
    lastTimestamp: number;
  };
}

const initialState: InitialState = {
  loading: false,
  error: null,
  data: {},
  dateSlots: {
    loading: false,
    data: [],
    error: null,
    lastTimestamp: 0,
  },
  slots: {
    loading: false,
    data: {},
    error: null,
    lastTimestamp: 0,
  },
  swapSlots: {
    loading: false,
    data: null,
    error: null,
    lastTimestamp: 0,
  },
};

const slice = createSlice({
  name: 'slots',
  initialState,
  reducers: {
    resetDaySlots: (state) => {
      state.dateSlots = initialState.dateSlots;
    },
    resetSlots: (state) => {
      state.slots = initialState.slots;
    },
    resetSwapSlots: (state) => {
      state.swapSlots = initialState.swapSlots;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSlotsByDate.pending, (state, action) => {
      state.dateSlots.loading = true;
      state.dateSlots.error = null;
      state.dateSlots.lastTimestamp = action.meta.arg.timestamp;
    });
    builder.addCase(getSlotsByDate.fulfilled, (state, action) => {
      if (state.dateSlots.lastTimestamp <= action.payload.timestamp) {
        state.dateSlots.loading = false;
        state.dateSlots.error = null;

        const { date } = action.meta.arg;
        const slotsData = action.payload.data.slots[date] || {};

        const slots: Slot[] = [];
        const employeeIds = Object.keys(slotsData);
        for (let index = 0; index < employeeIds.length; index += 1) {
          const employeeId = employeeIds[index];
          const slotData = slotsData[employeeId];

          if (slotData?.length) {
            const timeSlots: string[] = [];

            for (let timeSlotIndex = 0; timeSlotIndex < slotData.length; timeSlotIndex += 1) {
              timeSlots.push(slotData[timeSlotIndex].time);
            }

            slots.push({
              date,
              timeSlots,
              employeeId,
            });
          }
        }

        state.dateSlots.data = slots;
      }
    });
    builder.addCase(getSlotsByDate.rejected, (state, action) => {
      if (state.dateSlots.lastTimestamp <= action.payload!.timestamp) {
        state.dateSlots.loading = false;
        state.dateSlots.error = action.error;
      }
    });

    builder.addCase(getSlots.pending, (state, action) => {
      state.slots.loading = true;
      state.slots.error = null;
      state.slots.lastTimestamp = action.meta.arg.timestamp;
    });
    builder.addCase(getSlots.fulfilled, (state, action) => {
      if (state.slots.lastTimestamp <= action.payload.timestamp) {
        state.slots.loading = false;
        state.slots.error = null;
        state.slots.data[action.meta.arg.serviceIds.join(',')] = action.payload.data.slots;
      }
    });
    builder.addCase(getSlots.rejected, (state, action) => {
      if (state.slots.lastTimestamp <= action.payload!.timestamp) {
        state.slots.loading = false;
        state.slots.error = action.error;
      }
    });

    builder.addCase(getSwapSlots.pending, (state, action) => {
      state.swapSlots.loading = true;
      state.swapSlots.error = null;
      state.swapSlots.lastTimestamp = action.meta.arg.timestamp;
    });
    builder.addCase(getSwapSlots.fulfilled, (state, action) => {
      if (state.swapSlots.lastTimestamp === action.payload.timestamp) {
        state.swapSlots.loading = false;
        state.swapSlots.error = null;
        state.swapSlots.data = action.payload.data;
      }
    });
    builder.addCase(getSwapSlots.rejected, (state, action) => {
      if (state.swapSlots.lastTimestamp === action.payload!.timestamp) {
        state.swapSlots.loading = false;
        state.swapSlots.error = action.error;
        state.swapSlots.data = initialState.swapSlots.data;
      }
    });
  },
});

export const { resetDaySlots, resetSlots, resetSwapSlots } = slice.actions;

export default slice.reducer;
