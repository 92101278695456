import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Close as CloseIcon, DialogSection, IOption, Notice, NoticeStack, Nullable } from '@linetweet/linetweet-ui';
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { isEmpty, sortBy } from 'lodash';
import { Dayjs } from 'dayjs';

import { parseEmployeeNameWithEmoji, TimeHelper } from 'utils';
import { AsyncButton, EmployeeSelectField, Spin } from 'features/commons';
import { Appointment, CalendarEventTypeEnum, Employee, Service, Store } from 'types';
import { deleteCalendarEvents, getCalendarEvent, updateCalendarEvents } from 'store/appointments/thunks';
import { getSlots, getSlotsByDate, getSwapSlots } from 'store/slots/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { yupResolver } from '@hookform/resolvers/yup';

import { shallowEqual } from 'react-redux';
import { selectAvailableDates } from 'store/slots/selectors';
import { resetDaySlots, resetSlots, resetSwapSlots } from 'store/slots/slice';
import { IGetSlotsByDatesPayload } from 'store/slots/types';
import {
  AppointmentFormCustomerSection,
  AppointmentFormInformationSection,
  AppointmentFormRecurringSection,
  AppointmentFormServiceSection,
  AppointmentFormTimeSection,
  SlotSelectSection,
} from './components';
import { AppointmentFormSlotItem, CalendarEventCreateParams, ICalendarEventFormValues } from './types';
import {
  calendarEventFormValuesToUpdateCalendarEventParams,
  calendarEventToCalendarCreateValues,
  calendarEventToCalendarEventFormValues,
  createSlotOptions,
  getCreateCalendarEventFormDefaultValues,
  getDateRange,
} from './helpers';
import { FormDatePicker } from './subcomponents';
import { calendarEventFormSchema } from './util/calendarEventFormSchema';

import styles from './EditAppointment.module.scss';
import { selectAvailableEmployees, selectSlotEmployeesByServiceIds } from '../../store/employees/selectors';
import { selectStoreServices } from '../../store/store/selectors';
import { isRequired } from './util/isRequired';
import { fetchEmployees } from '../../store/employees/thunks';
import { resetUpdateCalendarEvent } from '../../store/appointments/slice';
import { createSwapSlotOptions } from './util/createSwapSlotOptions';

type Props = {
  appointmentId: string;
  type: CalendarEventTypeEnum;
  employeeId?: string;
  onClose: () => void;
  onCopyToNew: (params: CalendarEventCreateParams) => void;
  shouldReload?: boolean;
  isDisabled?: boolean;
  selectedDate?: Dayjs;
};

export function EditAppointment({ appointmentId, employeeId, onClose, onCopyToNew, shouldReload, isDisabled, type, selectedDate }: Props) {
  const intl = useIntl();
  const appointmentsState = useAppSelector((state) => state.appointments);
  const storeState = useAppSelector((state) => state.store);
  const calendarSlotsState = useAppSelector((state) => state.slots.slots, shallowEqual);
  const daySlotsState = useAppSelector((state) => state.slots.dateSlots, shallowEqual);
  const allServices = useAppSelector(selectStoreServices, shallowEqual);
  const updateCalendarEventState = useAppSelector((state) => state.appointments.updateCalendarEvent);
  const timeZone = useAppSelector((state) => state.store.data?.timeZone || 'Europe/Berlin', shallowEqual);
  const swapSlotsState = useAppSelector((state) => state.slots.swapSlots, shallowEqual);

  const [appointment, setAppointment] = useState<Appointment | undefined>(undefined);
  const [store, setStore] = useState<Store | undefined>();
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState<boolean>(false);
  const [slotsErrorShown, setSlotsErrorShown] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appointmentsState.loading) {
      return;
    }

    if (shouldReload) {
      if (!appointmentsState.singleAppointment) {
        dispatch(getCalendarEvent({ appointmentId }));
      } else if (appointmentsState.singleAppointment.id === appointmentId) {
        setAppointment(appointmentsState.singleAppointment);
      }
    } else {
      const nextAppointment = appointmentsState.data.find((appointmentItem) => appointmentItem.id === appointmentId);
      if (nextAppointment && appointment?.id !== nextAppointment?.id) {
        setAppointment(nextAppointment);
      }
    }
  }, [appointmentsState, appointment, appointmentId]);

  useEffect(() => {
    if (storeState.loading || storeState.error) {
      return;
    }
    setStore(storeState.data);
  }, [storeState]);

  const defaultValues = useMemo(
    () => (
      appointment
        ? calendarEventToCalendarEventFormValues(appointment, allServices, employeeId, intl)
        : getCreateCalendarEventFormDefaultValues(
          { type, ...(selectedDate ? { date: selectedDate.format('YYYY-MM-DD') } : {}) },
          timeZone,
          intl,
        )
    ),
    [appointment, allServices, employeeId],
  );

  const handleAppointmentEditCancel = useCallback(() => {
    onClose();
  }, []);

  const handleAppointmentEditCopy = useCallback(() => {
    if (appointment) {
      const appointmentParams = calendarEventToCalendarCreateValues(appointment, allServices, employeeId, timeZone);
      onCopyToNew(appointmentParams);
      onClose();
    }
  }, [defaultValues, appointment, allServices, employeeId]);

  const openConfirmationDialog = useCallback(() => {
    setIsConfirmationDialogOpened(true);
  }, []);

  const closeConfirmationDialog = useCallback(() => {
    setIsConfirmationDialogOpened(false);
  }, []);

  const handleDelete = useCallback(async () => {
    dispatch(deleteCalendarEvents({ id: appointmentId, reason: 'delete reason' }) as any);
    onClose();
  }, []);

  const getEmployeeFromStore = useCallback(
    (id: string) => {
      if (!storeState.data?.employees) {
        return null;
      }
      return storeState.data.employees.find((employee) => employee.id === id);
    },
    [storeState.data?.employees],
  );

  const form = useForm<ICalendarEventFormValues>({
    defaultValues,
    resolver: yupResolver(calendarEventFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (appointment) {
      form.reset(defaultValues);
    }
  }, [appointment, defaultValues]);

  const servicesValue = useWatch({ control: form.control, name: 'services' });
  const date = useWatch({ control: form.control, name: 'date' });
  const employeeValue = useWatch({ control: form.control, name: 'employee' });
  const typeValue = useWatch({ control: form.control, name: 'type' });
  const slotValue = useWatch({ control: form.control, name: 'slot' });

  const serviceIds = useMemo(() => servicesValue.map(({ value }) => value), [servicesValue]);

  const availableDays = useAppSelector((state) => selectAvailableDates(state, serviceIds, employeeValue), shallowEqual);

  const slotEmployees = useAppSelector((state) => selectSlotEmployeesByServiceIds(state, serviceIds, date), shallowEqual);

  const availableEmployees = useAppSelector((state) => selectAvailableEmployees(state, typeValue, serviceIds, date), shallowEqual);

  const employeesList = useMemo(() => {
    let employeeIsInList = false;
    const addOption = (employee: Employee) => {
      const { id, firstName, lastName } = employee;
      if (employeeValue === id) {
        employeeIsInList = true;
      }
      const nameValues = parseEmployeeNameWithEmoji(`${firstName} ${lastName}`);
      return {
        id,
        iconLabel: nameValues.name,
        label: nameValues.fullName,
      };
    };
    const options = availableEmployees.map((employee) => addOption(employee));
    if (employeeValue && !employeeIsInList) {
      const employee = getEmployeeFromStore(employeeValue);
      if (employee) {
        options.push(addOption(employee));
      }
    }

    return sortBy(options, 'label');
  }, [availableEmployees, employeeValue]);

  const slotOptions = useMemo(() => {
    const isRelatedFieldsDirty = form.formState.dirtyFields.date || form.formState.dirtyFields.services;

    let defaultSlot;
    if (!isRelatedFieldsDirty && appointment && defaultValues.slot) {
      const defaultEmployeeId = appointment.pinnedEmployee;
      if (defaultEmployeeId && (!employeeValue || employeeValue === defaultEmployeeId)) {
        defaultSlot = defaultValues.slot;
      }
    }
    return createSlotOptions({
      timeZone,
      employeeId: employeeValue,
      slots: daySlotsState.data,
      defaultSlot,
    });
  }, [daySlotsState, timeZone, employeeValue, appointment, defaultValues]);

  const swapSlotOptions = useMemo<AppointmentFormSlotItem[] | undefined>(() => (
    swapSlotsState.data ? createSwapSlotOptions(swapSlotsState.data, date, timeZone) : undefined
  ), [swapSlotsState.data, date, timeZone]);

  const isShowSlotSelection = useMemo(
    () => typeValue === CalendarEventTypeEnum.APPOINTMENT && Boolean(servicesValue.length),
    [typeValue, servicesValue],
  );

  const isShowTimeSelection = useMemo(() => {
    if (
      ![CalendarEventTypeEnum.BREAK, CalendarEventTypeEnum.BLOCKER, CalendarEventTypeEnum.TASK].includes(typeValue as CalendarEventTypeEnum)
    ) {
      return false;
    }
    return true;
  }, [typeValue]);

  const isShowServicesSection = useMemo(() => typeValue === CalendarEventTypeEnum.APPOINTMENT, [typeValue]);

  const isShowRecurringSection = useMemo(() => typeValue === CalendarEventTypeEnum.BREAK, [typeValue]);

  const onSubmit = form.handleSubmit(async (formValues) => {
    if (!store || !appointment) return;

    try {
      const params = calendarEventFormValuesToUpdateCalendarEventParams(formValues, appointment);

      const result = await dispatch(
        updateCalendarEvents({
          appointmentId: appointment.id,
          params,
        }),
      );

      if (updateCalendarEvents.fulfilled.match(result)) {
        onClose();
      }
    } catch (error) {}
  });

  const fetchSwapOptions = useCallback(() => {
    if (storeState.data && storeState.data.settings.swap && employeeValue) {
      dispatch(getSwapSlots({
        date,
        employeeId: employeeValue,
        serviceIds,
        timestamp: Date.now(),
        timezone: timeZone,
      }));
    }
  }, [employeeValue, date, employeeValue, serviceIds, timeZone, storeState.data]);

  useEffect(() => {
    if (updateCalendarEventState.error) {
      dispatch(getSlotsByDate({
        serviceIds,
        date,
        timestamp: Date.now(),
        timezone: timeZone,
      }));

      if (swapSlotsState.data) {
        fetchSwapOptions();
      }
    }
  }, [updateCalendarEventState.error]);

  useEffect(() => {
    setSlotsErrorShown(Boolean(calendarSlotsState.error || daySlotsState.error));
  }, [calendarSlotsState.error, daySlotsState.error]);

  useEffect(() => {
    if (updateCalendarEventState.error && typeValue === CalendarEventTypeEnum.APPOINTMENT) {
      dispatch(getSlotsByDate({ serviceIds, date, timestamp: Date.now(), timezone: timeZone }));
    }
  }, [updateCalendarEventState.error]);

  useEffect(() => {
    if (daySlotsState.loading) {
      return;
    }

    let slotToSet: AppointmentFormSlotItem | null = null;

    const optionByValueMap: Record<number, IOption> = {};
    for (let optionIndex = 0; optionIndex < slotOptions.length; optionIndex += 1) {
      const option = slotOptions[optionIndex];
      optionByValueMap[option.value] = option;
    }

    const possibleValues: AppointmentFormSlotItem[] = [];
    if (slotValue) {
      possibleValues.push(slotValue);
    }

    if (defaultValues.slot) {
      possibleValues.push(defaultValues.slot);
    }

    const possibleValue = possibleValues.find((slotOption) => optionByValueMap[slotOption.value]);

    if (possibleValue) {
      slotToSet = possibleValue;
    }

    form.setValue('slot', slotToSet, { shouldValidate: true });
  }, [slotOptions]);

  useEffect(() => {
    if (
      !isDisabled &&
      !daySlotsState.loading &&
      employeeValue &&
      !slotValue &&
      typeValue === CalendarEventTypeEnum.APPOINTMENT &&
      (!slotEmployees.find(({ id }) => id === employeeValue) || !slotOptions.length)
    ) {
      form.setError('employee', {
        type: 'employee-no-slots',
        message: intl.formatMessage({ id: 'calendarEvent.employee.noSlotsAvailableError' }),
      });
      return;
    }

    form.clearErrors('employee');
  }, [typeValue, slotEmployees, slotValue, employeeValue, daySlotsState.loading, slotOptions, isDisabled]);

  const prevSlotsPayload = useRef<Nullable<IGetSlotsByDatesPayload>>(null);
  useEffect(() => {
    if (!appointment || typeValue !== CalendarEventTypeEnum.APPOINTMENT) {
      return;
    }

    const payload = {
      serviceIds: allServices
        .filter((service: Service) => serviceIds.includes(service.id))
        .map((service) => service.id)
        .sort(),
      dates: getDateRange(date, timeZone),
      timestamp: Date.now(),
      timezone: timeZone,
    };

    let shouldFetch = !prevSlotsPayload.current;

    if (!shouldFetch && prevSlotsPayload.current!.dates[0] !== payload.dates[0]) {
      shouldFetch = true;
    }

    if (!shouldFetch && payload.serviceIds.length !== prevSlotsPayload.current!.serviceIds.length) {
      shouldFetch = true;
    }

    if (!shouldFetch) {
      for (let index = 0; index < payload.serviceIds.length; index += 1) {
        const payloadServiceId = payload.serviceIds[index];
        const prevPayloadServiceId = prevSlotsPayload.current!.serviceIds[index];

        if (payloadServiceId !== prevPayloadServiceId) {
          shouldFetch = true;
          break;
        }
      }
    }

    if (shouldFetch) {
      dispatch(getSlots(payload));

      prevSlotsPayload.current = payload;
    }
  }, [typeValue, date, serviceIds, allServices, timeZone]);

  useEffect(() => {
    if (!appointment || typeValue !== CalendarEventTypeEnum.APPOINTMENT) {
      return;
    }

    dispatch(
      getSlotsByDate({
        serviceIds: allServices
          .filter((service: Service) => serviceIds.includes(service.id))
          .map((service) => service.id)
          .sort(),
        date,
        timestamp: Date.now(),
        timezone: timeZone,
      }),
    );
  }, [typeValue, date, serviceIds, allServices]);

  useEffect(() => {
    if (date) {
      const from = TimeHelper.toDayjs(date, timeZone);
      dispatch(fetchEmployees({ from }));
    }
  }, [date, timeZone]);

  useEffect(
    () => () => {
      dispatch(resetDaySlots());
      dispatch(resetSlots());
      dispatch(resetUpdateCalendarEvent());
      dispatch(resetSwapSlots());
    },
    [],
  );

  useEffect(() => {
    dispatch(resetSwapSlots());
  }, [date, servicesValue, employeeValue]);

  if (!appointment || !store) {
    return <></>;
  }

  return (
    <>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open onClick={handleAppointmentEditCancel}>
        <Paper className={styles.formWrapper}>
          <FormProvider {...form}>
            <form>
              <Spin loading={calendarSlotsState.loading}>
                <Stack direction="column" spacing={1.5}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography className={styles.formTitle}>
                      {intl.formatMessage({ id: 'common.editEntity' }, { entity: intl.formatMessage({ id: `appointment.${typeValue}` }) })}
                    </Typography>
                    <IconButton size="small" className={styles.actionIconButton} onClick={handleAppointmentEditCancel}>
                      <CloseIcon className={styles.actionIcon} />
                    </IconButton>
                  </Stack>

                  {[CalendarEventTypeEnum.APPOINTMENT, CalendarEventTypeEnum.TASK].includes(typeValue as CalendarEventTypeEnum) && (
                    <Box>
                      <AppointmentFormCustomerSection size="small" isDisabled={isDisabled} schema={calendarEventFormSchema} />
                    </Box>
                  )}
                  {[CalendarEventTypeEnum.BREAK, CalendarEventTypeEnum.BLOCKER].includes(typeValue as CalendarEventTypeEnum) && (
                    <AppointmentFormInformationSection isDisabled={isDisabled} schema={calendarEventFormSchema} />
                  )}
                  {isShowSlotSelection && (
                    <Controller
                      control={form.control}
                      name="slot"
                      render={({ field }) => (
                        <SlotSelectSection
                          value={field.value}
                          onChange={(nextValue) => {
                            field.onChange(nextValue);
                            form.trigger('slot');
                          }}
                          options={slotOptions}
                          loading={daySlotsState.loading}
                          isWarning={!!form.formState.errors.slot}
                          isDisabled={!!isDisabled}
                          before={(
                            <Controller
                              name="date"
                              render={({ field: dateField }) => (
                                <FormDatePicker
                                  isDisabled={!!isDisabled}
                                  date={dateField.value}
                                  suggestedDays={availableDays}
                                  onChange={dateField.onChange}
                                />
                              )}
                            />
                          )}
                          employeeId={employeeValue}
                          swapEnabled={storeState.data && storeState.data.settings.swap}
                          swapOptions={swapSlotOptions}
                          onFetchSwapOptionsClick={fetchSwapOptions}
                          swapOptionsLoading={swapSlotsState.loading}
                          swapOptionsError={!!swapSlotsState.error}
                        />
                      )}
                    />
                  )}
                  {isShowTimeSelection && (
                    <DialogSection title={intl.formatMessage({ id: 'appointment.date' })}>
                      <Controller
                        name="date"
                        render={({ field }) => <FormDatePicker date={field.value} onChange={field.onChange} isDisabled={!!isDisabled} />}
                      />

                      <AppointmentFormTimeSection isDisabled={!!isDisabled} />
                    </DialogSection>
                  )}

                  {isShowServicesSection && (
                    <AppointmentFormServiceSection
                      services={allServices}
                      defaultValues={defaultValues.services}
                      isDisabled={!!isDisabled}
                    />
                  )}

                  {isShowRecurringSection && <AppointmentFormRecurringSection />}

                  <DialogSection title={intl.formatMessage({ id: 'appointment.specificEmployee' })}>
                    <Controller
                      control={form.control}
                      name="employee"
                      render={({ field, fieldState }) => (
                        <EmployeeSelectField
                          values={employeesList}
                          disabled={!!isDisabled}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          error={!!fieldState.error}
                          helperText={
                            !['nullable', 'required'].includes(fieldState.error?.type as string) ? fieldState.error?.message : undefined
                          }
                          required={isRequired(calendarEventFormSchema, field.name)}
                        />
                      )}
                    />
                  </DialogSection>

                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {isDisabled ? (
                      <Button variant="outlined" onClick={handleAppointmentEditCopy} disabled={!appointment}>
                        {intl.formatMessage({ id: 'appointment.copyToNew' })}
                      </Button>
                    ) : (
                      <Stack direction="column" spacing={1} flex={1}>
                        <Stack direction="row">
                          <Button variant="outlined" onClick={openConfirmationDialog}>
                            {intl.formatMessage({ id: 'common.delete' })}
                          </Button>
                          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1.5} flex={1}>
                            <Button variant="outlined" onClick={handleAppointmentEditCancel}>
                              {intl.formatMessage({ id: 'common.abort' })}
                            </Button>
                            <AsyncButton variant="contained" onClick={onSubmit}>
                              {intl.formatMessage({ id: 'common.save' })}
                            </AsyncButton>
                          </Stack>
                        </Stack>

                        {form.formState.isSubmitted && !(form.formState.isValid && isEmpty(form.formState.errors)) && (
                          <FormHelperText error>{intl.formatMessage({ id: 'common.formHasErrors' })}</FormHelperText>
                        )}
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Spin>
            </form>
          </FormProvider>
        </Paper>
      </Backdrop>
      <Dialog open={isConfirmationDialogOpened}>
        <DialogTitle>{intl.formatMessage({ id: 'calendar.deleteAppointmentDialogTitle' })}</DialogTitle>
        <DialogContent>{intl.formatMessage({ id: 'calendar.deleteAppoinctmentDialog' })}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeConfirmationDialog}>
            {intl.formatMessage({ id: 'common.abort' })}
          </Button>
          <Button onClick={handleDelete}>{intl.formatMessage({ id: 'common.confirm' })}</Button>
        </DialogActions>
      </Dialog>
      <NoticeStack>
        <Notice
          open={slotsErrorShown}
          onClose={() => setSlotsErrorShown(false)}
          severity="error"
          autoHideDuration={60000}
          title={intl.formatMessage({ id: 'slot.slotsFetchErrorTitle' })}
        />
      </NoticeStack>
    </>
  );
}

EditAppointment.defaultProps = {
  employeeId: undefined,
  shouldReload: undefined,
  isDisabled: undefined,
};
